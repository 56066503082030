const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const facetBarFields = [...wheelsFields, ...tiresFields];
const ignoreFields = [...facetBarFields, 'Universal'];

const brandSelectionPageUrl = '/pages/shop-by-brand';
const collectionsPageUrl = '/collections';
const searchPageUrl = '/search';

const isDesktopWidth = globalThis.innerWidth >= 768;

globalThis.Convermax.facetPanelIgnoreField = (field) => {
  return (
    (window.Convermax.universalCollection && field === 'Fitment') ||
    (isDesktopWidth && facetBarFields.includes(field))
  );
};

globalThis.Convermax.productIsNew = (publishedAt) => {
  return (Date.now() - new Date(publishedAt)) / (1000 * 60 * 60 * 24) < window.themeSettings.time_new;
};

function getPageType(defaults) {
  const {
    location: { pathname },
  } = window;

  if (pathname === collectionsPageUrl || pathname === brandSelectionPageUrl) {
    return 'parts';
  }

  return defaults.getter();
}

function getFitmentSearchTitle() {
  switch (window.location.pathname) {
    case brandSelectionPageUrl:
      return 'Brands';
    case collectionsPageUrl:
      return 'Collections';
    case searchPageUrl:
      return 'Search';
  }

  if (window.location.href.indexOf('collections') > -1) {
    const title = document.querySelector('body#template-collection .page-title')?.textContent.trim();
    return title;
  }

  return 'Parts';
}

const updateCallback = () => {
  window.updateWishListButtons();
  window.productButtonReplace();

  if (window._affirm_config) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }
};

export default {
  platform: 'shopify',
  searchPageUrl,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  requestTimeout: window.Convermax.config?.requestTimeout,
  page: {
    getPageType,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      {
        pathname: collectionsPageUrl,
        field: window.Convermax.config?.categorySelectionPageField ?? 'category',
      },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: window.themeSettings?.enable_ymm_redirect ?? true,
    getFitmentSearchTitle,
    isAutoRedirectDisabled: window.Convermax.config?.isAutoRedirectDisabled,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  product: {
    priceFormatter: {
      format(price) {
        return window.Shopify.formatMoney(price * 100);
      },
    },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'SearchPageHeader',
      type: 'VehicleWidget',
      location: 'body#template-search .page-title',
      template: 'fitmentSearch/pageHeader',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: { selector: 'body#template-collection .collection-content', class: 'cm_CategoryPage pt-4' },
      template: 'CategoryPage',
    },
    {
      name: 'CategoryPageHeader',
      type: 'VehicleWidget',
      location: 'body#template-collection .page-title',
      template: 'fitmentSearch/pageHeader',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: 'body#template-list-collections .list-collections',
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: window.Convermax.multiLevelCategories || false,
    },
    {
      name: 'FacetCategoryTilesPageHeader',
      type: 'VehicleWidget',
      location: 'body#template-list-collections .page-title',
      template: 'fitmentSearch/pageHeader',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetBrandTilesPageHeader',
      type: 'VehicleWidget',
      location: '#shopify-section-template-page-brands .page-title',
      template: 'fitmentSearch/pageHeader',
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
        'collection_sort': 'Featured',
      },
      sortSelectClass: 'text-secondary py-1 pl-2 pr-4',
    },
    {
      name: 'SearchBox',
      location: '#header-searchbar',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '#nav-link-search',
        class: 'text-secondary text-decoration-none',
        wrapper: 'a',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: { replace: '#cm_Garage', class: 'nav-item d-none d-lg-block mr-4', wrapper: 'li' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'SingleVehicleGarage',
      location: {
        replace: '#cm_mGarage',
        class: 'nav-item d-lg-none nav-link text-secondary cm_garage-mobile',
        wrapper: 'li',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: 'body#template-index .ymm-bar',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'CategoryVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      doNotRedirectOnVehicleSelect: true,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { replace: '#cm_VerifyFitment' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTabDesktop',
      type: 'FitmentTable',
      location: '#cm_fitment-table_tab',
      template: 'fitmentSearch/fitmentTableTabDesktop',
    },
    {
      name: 'FitmentTableTabMobile',
      type: 'FitmentTable',
      location: '#product-accordion-heading-fitment-table',
      template: 'fitmentSearch/fitmentTableTabMobile',
    },
    {
      name: 'FitmentTable',
      location: '.tab-content #cm_fitmentTable',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'MobileFitmentTable',
      type: 'FitmentTable',
      location: '.product-accordion #cm_fitmentTable',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      location: { class: ' align-items-center justify-content-center' },
      template: 'fitmentSearch/dialog',
    },
  ],
};
